import React, { useState, useEffect, useContext, createContext } from "react";
import axiosClient from "../axiosClient";

export const B2BContext = createContext();

const B2BContextProvider = ({ children }) => {

  const [chooseProduct, setChooseProduct] = useState([])
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState([]);
  const [showmenu, setshowmenu] = useState(window.innerWidth > 768)
  const [showbar, setshowbar] = useState(window.innerWidth > 768)
  const [showmenue, setshowmenue] = useState(window.innerWidth > 768)
  const [primaryAddress, setprimaryAddress] = useState({})


  const getProductData = (id) => {
    axiosClient.get(`/b2b/addtocart/${id}`).then((res) => {
      setProducts(res.data.products);
      console.log(res.data.products)
      setImage(res.data.images);
    });
  }

  return (
    <B2BContext.Provider value={{
      chooseProduct, setChooseProduct, products, setProducts, image, setImage, getProductData, showmenu, setshowmenu, showbar, setshowbar, showmenue, setshowmenue, primaryAddress, setprimaryAddress
    }}>
      {children}
    </B2BContext.Provider>
  );
}

export default B2BContextProvider