import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './component/useAuth';
import { SubAdminAuthProvider } from './component/context/useSubAdminAuth';
import B2BContextProvider from './component/B2BContext/B2BContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SubAdminAuthProvider>
      <AuthProvider>
        <B2BContextProvider>
          <App />
        </B2BContextProvider>
      </AuthProvider>
    </SubAdminAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
