// // import AWS from "aws-sdk";
// // import React, { useState } from "react";

// // function UploadImage() {
// //   // Create state to store file
// //   const [file, setFile] = useState(null);

// //   // Function to upload file to s3
// //   const uploadFile = async () => {
// //     // S3 Bucket Name
// //     const S3_BUCKET = "bucket-name";

// //     // S3 Region
// //     const REGION = "region";

// //     // S3 Credentials
// //     AWS.config.update({
// //       accessKeyId: "youraccesskeyhere",
// //       secretAccessKey: "yoursecretaccesskeyhere",
// //     });
// //     const s3 = new AWS.S3({
// //       params: { Bucket: S3_BUCKET },
// //       region: REGION,
// //     });

// //     // Files Parameters

// //     const params = {
// //       Bucket: S3_BUCKET,
// //       Key: file.name,
// //       Body: file,
// //     };

// //     // Uploading file to s3

// //     var upload = s3
// //       .putObject(params)
// //       .on("httpUploadProgress", (evt) => {
// //         // File uploading progress
// //         console.log(
// //           "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
// //         );
// //       })
// //       .promise();

// //     await upload.then((err, data) => {
// //       console.log(err);
// //       // Fille successfully uploaded
// //       alert("File uploaded successfully.");
// //     });
// //   };
// //   // Function to handle file and store it to file state
// //   const handleFileChange = (e) => {
// //     // Uploaded file
// //     const file = e.target.files[0];
// //     // Changing file state
// //     setFile(file);
// //   };
// //   return (
// //     <div className="">
// //       <div>
// //         <input type="file" onChange={handleFileChange} />
// //         <button onClick={uploadFile}>Upload</button>
// //       </div>
// //     </div>
// //   );
// // }

// // export default UploadImage;

import React, { useState, useEffect } from 'react';
import axiosClient from './axiosClient';

export default function UploadImage({ onImageUpload }) {
  const [file, setFile] = useState(null);

  useEffect(() => {
    console.log(file);
  }, [file]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  // const handleUpload = () => {
  //     const formData = new FormData();
  //     formData.append('image', image);

  //     axios
  //         .post('/upload', formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data',
  //             },
  //         })
  //         .then((response) => {
  //             console.log(response.data);
  //             loadImages();
  //         })
  //         .catch((error) => {
  //             console.error('Image upload error: ' + error);
  //         });
  // };

// Function to handle the file upload
const handleUpload = async (event) => {
  event.preventDefault();
  // Check if the file size is greater than 1 MB
  if (file.size > 1000000) {
    // Alert the user if the file size exceeds 1 MB
    alert("Your file size is more than 1 MB!! \nPlease ensure the file size is below 1 MB");
  } else {
    // Proceed if a file is selected
    if (file) {
      console.log('Selected file:', file);
      // Create a new FormData object to store the file data
      const formData = new FormData();
      // Append the selected file to the FormData object with the key 'file'
      formData.append('file', file);
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}:`, pair[1]); // Logs key-value pairs in FormData
      }

      try {
        // Make an asynchronous POST request to the '/upload' endpoint with the FormData object
        const response = await axiosClient.post(`/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // Uncomment the following line if you want to load the images after the upload
        // loadImages();
        
        // Retrieve the image ID from the response data
        const imageId = response.data.imageId;
        // Alert the user with the image ID
        alert(imageId);
        // Pass the image ID to the parent component using the onImageUpload callback
        onImageUpload(imageId);
      } catch (error) {
        // Log any errors that occur during the upload process
        console.error('Error uploading image:', error);
      }
    }
  }
};

  // const loadImages = () => {
  //   axiosClient
  //     .get('/images')
  //     .then((response) => {
  //       setImages(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Image retrieval error: ' + error);
  //     });
  // };

    // useEffect(() => {
    //   loadImages();
    // }, []);

  return (
    <div className='image-upload'>
      {/* <h1>Image Upload</h1> */}
      <input type="file" onChange={handleFileChange} accept='image/*' />
      <button 
        className='btn btn-warning m-2' 
        onClick={handleUpload}
        disabled={!file}
      >
        Upload Image
      </button>

      <div>
        {/* {images.map((img) => (
          <div key={img.id}>
            <img
              src={`${process.env.REACT_APP_HOST}/${img.path}`}
              alt={img.name}
              width="50"
            />
            <p>{img.name}</p>
          </div>
        ))} */}
      </div>
      <p className='text-danger'>*Please ensure the file size is below 1 MB*</p>
    </div>
  );
}














// import React, { useState, useEffect } from 'react';
// import AWS from 'aws-sdk';

// // Configure AWS SDK
// AWS.config.update({
//   region: 'us-west-1', // e.g., 'us-west-2'
//   credentials: new AWS.Credentials('AKIAUVRBXC4MJFQL2PUR', 'rAogokaHKcb9rfHEK9D66j/ydKRHfHyNtqlzBp6t')
// });

// // const s3 = new AWS.S3({
// //   accessKeyId: "AKIAUVRBXC4MJFQL2PUR",
// //   secretAccessKey: "rAogokaHKcb9rfHEK9D66j/ydKRHfHyNtqlzBp6t",
// //   region: 'eu-north-1' // e.g., 'us-west-1'
// // });

// const s3 = new AWS.S3();

// export default function UploadImage({ onImageUpload }) {
//   const [file, setFile] = useState(null);
//   const [images, setImages] = useState([]);

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (file.size > 1000000) { // 1 MB in bytes
//       alert("Your file size is more than 1 MB! Please ensure the file size is below 1 MB.");
//       return;
//     }

//     if (file) {
//       const params = {
//         Bucket: 'health-hepta-aws-bucket ',
//         Key: `images/${file.name}`, // Path where file will be stored in the S3 bucket
//         Body: file,
//         ContentType: file.type,
//         ACL: 'public-read' // Adjust permissions as needed
//       };

//       try {
//         const data = await s3.upload(params).promise();
//         const imageId = data.Key; // Use the S3 object key as the image ID
//         loadImages();
//         onImageUpload(imageId); // Pass the image ID to the parent component
//       } catch (error) {
//         console.error('Error uploading image:', error);
//       }
//     }
//   };

//   const loadImages = () => {
//     // You might need to adjust this depending on how you list images from S3
//     s3.listObjectsV2({ Bucket: 'health-hepta-aws-bucket ', Prefix: 'images/' }, (err, data) => {
//       if (err) {
//         console.error('Error retrieving images:', err);
//         return;
//       }

//       const imageList = data.Contents.map((obj) => ({
//         id: obj.Key,
//         path: obj.Key,
//         name: obj.Key.split('/').pop()
//       }));
//       setImages(imageList);
//     });
//   };

//   return (
//     <div className='image-upload'>
//       <input type="file" onChange={handleFileChange} />
//       <button className='btn btn-warning m-2' onClick={handleUpload}>Upload Image</button>

//       <div>
//         {images.map((img) => (
//           <div key={img.id}>
//             <img
//               src={`https://health-hepta-aws-bucket.s3.us-west-1.amazonaws.com/${img.path}`}
//               alt={img.name}
//               width="50"
//             />
//             <p>{img.name}</p>
//           </div>
//         ))}
//       </div>
//       <p className='text-danger'>*Please ensure the file size is below 1 MB*</p>
//     </div>
//   );
// }
