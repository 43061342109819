import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
    Link,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import HashLoader from 'react-spinners/HashLoader';
import logo from '../../img/logo.jpeg';
import axiosClient from '../axiosClient';
import '../style.css';
import { B2BContext } from '../B2BContext/B2BContext';
const ChoosePinCodeModal = lazy(() => import('../ChoosePinCodeModal'));


var login = false;
export default function B2BHeader() {

    const { chooseProduct, setChooseProduct,showmenu, setshowmenu } = useContext(B2BContext)
    const navigate = useNavigate();
    //main for connecting backend with Session
    axiosClient.defaults.withCredentials = true;

    const location = useLocation()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [numOfItem, setnumOfItem] = useState(0)
    const [loggedIn, setLoggedIn] = useState(0);
    const [userRole, setUserRole] = useState();
    const [locations, setLocation] = useState([])
    const [products, setProducts] = useState([])
    const [chooseLocation, setChooseLocation] = useState([])
    // const [chooseProduct, setChooseProduct] = useState([])
    const [selectLocation, setSelectLocation] = useState()
    const [userLocation, setUserLocation] = useState()
    const [active, setActive] = useState(false)

    const user_id = localStorage.getItem('user_id');
    const logedIn = localStorage.getItem('LogedIn');
    const role = localStorage.getItem('role');

    useEffect(() => {
        if (logedIn) {
            axiosClient.get(`/b2b/cart/${user_id}/${role}`).then((res) => {
                if (res.data != null && res.data.length > 0) {
                    setnumOfItem(res.data[0].length);
                } else {
                    setnumOfItem(0);
                }
            }).then((err) => {
                console.log("An error occured while fetching number of items in cart =", err);
            });
        }
    }, []);


    // useEffect(() => {
    //     axiosClient.get(`/sub_admin/profile-details`)
    //         .then(res => {
    //             // console.log(res.data)
    //             if (res.data.length > 3) {
    //                 setnumOfItem(res.data[0]);
    //                 setLoggedIn(res.data[1]);
    //                 // console.log(loggedIn);
    //                 setUserLocation(res.data[2]);
    //                 setUserRole(res.data[3]);
    //                 localStorage.setItem("current_pin_code", res.data[2]);

    //             } else if (res.data.length === 3) {
    //                 setnumOfItem(res.data[0]);
    //                 setLoggedIn(res.data[1]);
    //                 setUserRole(res.data[2]);
    //                 // localStorage.setItem("current_pin_code", res.data[2]);
    //             }
    //         })
    // }, [user_id]);

    useEffect(() => {
        const storedData = localStorage.getItem('user_id');
        // console.log(storedData)
        // alert(storedData)
        if (storedData) {
            axiosClient.get(`/sub-admin/home/profile/${storedData}`).then((response) => {
                // alert(response.data[0].pin_code);

                //   if (response.data !== null) {
                //     setUser(response.data[0]);
                //     setUserAddress(response.data[1]);
                //     setImages(response.data[2]);
                //     setUserRole(response.data[0].role)
                //     setRolePresentStatus(response.data[0].role);

                //   }
                // console.log(response.data)
                if (response) {
                    login = true
                    // console.log("profile", response.data)
                    if(!localStorage.getItem("current_pin_code")){
                        localStorage.setItem("current_pin_code", response.data[0].pin_code);
                    }
                    localStorage.setItem("role", response.data[0].role);
                    setSelectLocation(response.data[0].pin_code)
                }
            }).catch((error) => {
                console.log(error)  // handle error
            });
        }
    }, []);
    useEffect(() => {
        if (userLocation !== 0 || userLocation !== undefined) {
            // setSelectLocation(userLocation)
            setSelectLocation(localStorage.getItem("current_pin_code"))
        }

    }, [userLocation])
    useEffect(() => {
        axiosClient.get(`/locations`)
            .then(res => {
                setLocation(res.data);
                // console.log("location", res.data)
                // setChooseLocation(res.data)
            })
    }, [])
    // useEffect(() => {
    //     axiosClient.get(`/b2b/search`)
    //         .then(res => {
    //             setProducts(res.data);
    //             // setChooseLocation(res.data)
    //         })
    // }, [])

    // console.log("loggedIn", loggedIn)
    useEffect(() => {
        if (loggedIn !== undefined && loggedIn !== null && loggedIn !== 0) {
            login = true;
        }
    }, [loggedIn])

    // handle logout previous funcion
    // try {
    //     const response = await axiosClient.post(`/profile`);
    //     if (response.data.success) {
    //         setLoggedIn(0);
    //         localStorage.removeItem('LogedIn');
    //         localStorage.removeItem('user_id');
    //         navigate('/sub-admin/login');
    //     } else {
    //         // Handle logout failure
    //         console.error(response.data.message);
    //     }
    // } catch (error) {
    //     console.error('An error occurred:', error);
    // }

    const handleLogout = async () => {
        if (role === 'b2b_employee') {
            localStorage.removeItem('LogedIn');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role');
            localStorage.removeItem('current_pin_code');
            localStorage.removeItem('token');
            localStorage.removeItem("location")
            navigate('/b2b/emp/login');
        } else {
            localStorage.removeItem('LogedIn');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role');
            localStorage.removeItem('current_pin_code');
            localStorage.removeItem('token');
            localStorage.removeItem("location")
            navigate('/sub-admin/login');
        }
    };
    const param = useParams();
    if (param.selectLocation !== undefined) {
        // console.log(param.selectLocation)
    } else {
        // console.log(param)
    }
    const [values, setValues] = useState({
        input: ''
    })
    const setValueTOFilter = async (name) => {
        setValues({
            input: name
        })
    }
    // const handleFilter = (event) => {
    //     setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    //     const searchword = event.target.value.toLowerCase();
    //     const newFilter = products.filter((value) => {
    //         return value.name.toLowerCase().includes(searchword);
    //     });
    //     if (searchword === "") {
    //         setChooseProduct([]);
    //     } else {
    //         setChooseProduct(newFilter);
    //     }
    // };
    const handleFilter = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
        const searchword = event.target.value.toLowerCase();
        // console.log("search word is"+ searchword);

        // const newFilter = products.filter((value) => {
        //     return value.name.toLowerCase().includes(searchword);
        // });


        if (searchword === "") {
            setChooseProduct([]);
        } else {
            // setChooseProduct(newFilter);
            // /b2b/search/:key
            axiosClient.get(`/b2b/search/${searchword}`)
                .then(res => {
                    setChooseProduct(res.data);
                    // setChooseLocation(res.data)
                })
        }
    };

    let current_pin_code;
    current_pin_code = localStorage.getItem('current_pin_code');

    // const [searchLocation, setSearchLocation] = useState([]);

    const [searchValue, setSearchValue] = useState({
        input: ''
    })
    //   const handleLocationFilter = (event) => {
    //     setSearchValue(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    //     const searchword = event.target.value.toLowerCase();

    //     const filtered = locations.filter((item) => {
    //       const pin_code = item.pin_code.toString().toLowerCase();
    //       const search = searchword.toLowerCase();
    //       return pin_code.includes(search);
    //     });
    //     if (searchword === "") {
    //         setSearchLocation([]);
    //     } else {
    //         setSearchLocation(filtered);
    //     }
    //   };

    //   const setLocationValueTOFilter = async (pin_code) => {
    //     setSearchValue({
    //         input: pin_code,
    //     });
    //     setChooseLocation(pin_code);
    //     setSearchLocation([]);
    // }

    useEffect(() => {
        setSearchValue({
            input: current_pin_code,
        });
        console.log(current_pin_code)
    }, [current_pin_code])
    const searchMedicine = async () => {
        try {
            // console.log("values",values.input);
            console.log(selectLocation);


            const response = await axiosClient.post(`/b2b/search`, values);
            // const response = await axiosClient.get('/product');
            if (response.data !== null) {
                if (response.data[0].length == 1) {
                    navigate(`/b2b/addtocart/${response.data[0][0].product_id}`,
                        {
                            state: {
                                data: response.data,
                                location: selectLocation
                            }
                        })
                } else {
                    navigate(`/b2b/medicines/${values.input}`,
                        {
                            state: {
                                data: response.data,
                                location: selectLocation
                            }
                        })
                }

                setValues({
                    input: ''
                });
                setChooseProduct([]);
                //    console.log(response.data)
            } else {
                // Handle logout failure
                console.error(response.data.message);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }
    const handleClick = event => {
        setChooseProduct([]);
    };

    const hendlelogin = () => {
        navigate('/sub-admin/login')
    }

    const hendlecart = () => {
        navigate('/b2b/cart')
    }

    const handleClickProfile = () => {
        if (role == 'b2b_employee') {
            navigate('/b2b/emp/home', { state: { loggedIn: true } });
        } else {
            navigate('/sub-admin/home', { state: { loggedIn: true } });
        }
    };

    const hendleHomePage = () => {
        setChooseProduct([])
        navigate("/")
    }

    useEffect(() => {
        if (logedIn) {
            login = true;
        }
    }, []);

    function getLocation() {
        // const locationDiv = document.getElementById('location');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            // locationDiv.innerHTML = "Geolocation is not supported by this browser.";
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        console.log(lat, lon)
        getAddressFromCoords(lat, lon);
    }

    function showError(error) {
        let message = "";
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = "User denied the request for Geolocation.";
                break;
            case error.POSITION_UNAVAILABLE:
                message = "Location information is unavailable.";
                break;
            case error.TIMEOUT:
                message = "The request to get user location timed out.";
                break;
            case error.UNKNOWN_ERROR:
                message = "An unknown error occurred.";
                break;
        }
        // document.getElementById('location').innerHTML = message;
        console.log(message);
    }

    function getAddressFromCoords(lat, lon) {
        const apiKey = 'AIzaSyD_piVQLCmi3ZIQfDW9TwM3z7d3O2Lj6bk'; // Replace with your Google Maps API key
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);

                if (data.status === "OK") {
                    const address = data.results[0].formatted_address;
                    // document.getElementById('location').innerHTML = `Location: ${address}`;
                    console.log(address);
                } else {
                    // document.getElementById('location').innerHTML = "Unable to retrieve the address.";
                    console.log("Unable to retrieve the address.");
                }
            })
            .catch(error => {
                console.error('Error with geocoding API:', error);
                // document.getElementById('location').innerHTML = "Failed to get location.";
            });
    }

    useEffect(() => {
        getLocation();
    })

    return (
        <>
            {/* {active ? <>
                <div className="HamburgerMenu" style={{ width: '50vw' }}>
                    <h5>Hi User</h5>
                    <ul>
                        <li><Link to='/profile' className='text-light li'>Profile</Link></li>
                        <li>Doctors</li>
                        <li>Medicine Shops</li>
                        <li>Lab Tests</li>
                        <li>Clinics</li>
                        <li>Log Out</li>
                    </ul>
                    <span onClick={() => setActive(!active)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7vw" height="5vh" fill="white" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </span>
                </div>
            </> : <>
            </>
            } */}

            <div className='Header pb-2' style={{ backgroundColor: "#2b405b" }} >
                <nav className="headerWidth mx-auto py-2 d-flex align-items-center justify-content-between">




                    {/* first section ------------------- */}

                    <div className="right">
                        <div className="d-flex justify-content-center align-items-center w-100" >

                            {/* logo  */}
                            <div onClick={hendleHomePage} style={{ cursor: "pointer" }}>
                                <img src={logo} alt="Logo" className="lg" style={{ borderRadius: "50%", border: "1px solid white" }} />
                            </div>
                            {/* <div className="container-fluid line-header" onClick={() => setActive(!active)} >
                                {active ? <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7vw" height="5vh" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </> : <>

                                    <span className="navbar-toggler-icon line-icon-header"></span>
                                </>
                                }
                            </div> */}

                            {/* name  */}
                            <div className='d-none d-md-block text-decoration-none' onClick={hendleHomePage} style={{ cursor: "pointer" }}>
                                <div>
                                    <h5 className='text-light mx-1 mt-2 logo-text' style={{ fontWeight: '700' }}>Healthhepta.com</h5>
                                </div>
                            </div>

                            <div className='text-light ms-2 ms-md-3' onClick={handleShow} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='carth' color="#ffffff" fill="none">
                                    <path d="M18 18C19.2447 18.4244 20 18.9819 20 19.5925C20 20.9221 16.4183 22 12 22C7.58172 22 4 20.9221 4 19.5925C4 18.9819 4.75527 18.4244 6 18" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" />
                                    <path d="M15 9.5C15 11.1569 13.6569 12.5 12 12.5C10.3431 12.5 9 11.1569 9 9.5C9 7.84315 10.3431 6.5 12 6.5C13.6569 6.5 15 7.84315 15 9.5Z" stroke="currentColor" strokeWidth="1.5" />
                                    <path d="M12 2C16.0588 2 19.5 5.42803 19.5 9.5869C19.5 13.812 16.0028 16.777 12.7725 18.7932C12.5371 18.9287 12.2709 19 12 19C11.7291 19 11.4629 18.9287 11.2275 18.7932C8.00325 16.7573 4.5 13.8266 4.5 9.5869C4.5 5.42803 7.9412 2 12 2Z" stroke="currentColor" strokeWidth="1.5" />
                                </svg>

                                <span className='ps-1'>{searchValue.input ? searchValue.input : "Pin"}</span>
                            </div>
                        </div>
                    </div>


                    {/* secound section --------------------------- */}

                    {/* scarch  */}
                    <div className="d-none d-md-block" >
                        <div style={{ display: 'flex', }}>
                            <input
                                className="form-control text-dark"
                                name="input"
                                onChange={handleFilter}
                                placeholder="Search Medicine Etc"
                                autoComplete='off'
                                value={values.input}
                                style={{
                                    width: '40vw',
                                    height: "4vh",
                                    fontSize: '0.8rem',
                                    borderTopLeftRadius: '6px',
                                    borderTopRightRadius: '0px',
                                    borderBottomLeftRadius: '6px',
                                    borderBottomRightRadius: '0px',
                                    backgroundColor: "#eaecee",
                                    color: "black"
                                }}
                            />

                            <button type="button" onClick={searchMedicine} className="btn" style={{ backgroundColor: '#febd69', color: 'black', borderTopLeftRadius: '0px', borderTopRightRadius: '6px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '6px', height: "4vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ fontWeight: "bold" }} width="16" height="16" fill="black" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                </svg>

                            </button>
                        </div>
                        {chooseProduct.length !== 0 && (
                            <div className="inputResult z-3" onClick={handleClick} style={{}}>
                                {chooseProduct.map((product, index) => {
                                    return <div onClick={() => setValueTOFilter(product.name)} style={{ color: 'black' }}>
                                        <p style={{ cursor: 'pointer', padding: '0px' }} key={index}>{product.name}</p>
                                    </div>
                                }
                                )}
                            </div>
                        )}
                    </div>



                    {/* third section  -----------------------------------------------*/}

                    <div className='login-order' style={{ alignItems: "center" }}>

                        {/* new pin code  */}
                        {/* <div className="mx-1 mx-md-3 rounded" onClick={handleShow} style={{ backgroundColor: "rgb(254, 189, 105)", color: "#212121"}}>
                           <span className='p-1 p-md-3 fn'> Pin Code</span>
                        </div> */}

                        {/* offers */}

                        <div className='text-light me-2 me-md-3' style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>

                            <svg xmlns="http://www.w3.org/2000/svg" className='carth' fill='white' viewBox="0 0 48 48"><path d="m40.43 23.92 3.33-4.49a1 1 0 0 0 .17-.86 1 1 0 0 0-.58-.66l-5.12-2.18.66-5.44a1 1 0 0 0-1.11-1.11l-5.6.65-2.26-5.23a1 1 0 0 0-.66-.6 1 1 0 0 0-.86.17l-4.48 3.4-4.49-3.33a1 1 0 0 0-.86-.16 1 1 0 0 0-.66.57l-2.2 5.17-5.56-.64a1 1 0 0 0-.82.29 1 1 0 0 0-.29.82l.67 5.5L4.6 18a1 1 0 0 0-.57.66 1.05 1.05 0 0 0 .17.86L7.55 24 4.2 28.49a1 1 0 0 0-.16.86 1 1 0 0 0 .57.66L9.7 32.2 9 37.79a1 1 0 0 0 1.11 1.12l5.62-.67L18 43.4a1 1 0 0 0 .66.57 1.15 1.15 0 0 0 .26 0 1 1 0 0 0 .6-.2L24 40.45l4.49 3.35a1 1 0 0 0 1.51-.41l2.21-5.14 5.56.66a1 1 0 0 0 1.11-1.12l-.66-5.64 5.17-2.23a1 1 0 0 0 .57-.66 1.05 1.05 0 0 0-.17-.86zm-3.68 6.69a1 1 0 0 0-.6 1l.61 5.12-5-.59a1 1 0 0 0-1 .6l-2 4.66-4.07-3a1 1 0 0 0-1.19 0l-4.08 3-2-4.67a1 1 0 0 0-.92-.61h-.12l-5.1.6.59-5.08a1 1 0 0 0-.59-1l-4.62-2 3-4.07a1 1 0 0 0 0-1.19l-3-4.08 4.63-2a1 1 0 0 0 .6-1l-.61-5 5 .59a1 1 0 0 0 1-.61l2-4.69 4.07 3a1 1 0 0 0 1.2 0l4.07-3.06 2.05 4.74a1 1 0 0 0 1 .6l5.08-.59-.6 4.92a1 1 0 0 0 .6 1.05l4.65 2-3 4.07a1 1 0 0 0 0 1.2l3.06 4.07z" /><path d="M27.5 25a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5zm0 3a.5.5 0 0 1-.5-.5.5.5 0 0 1 1 0 .5.5 0 0 1-.5.5zM20.5 23a2.5 2.5 0 1 0-2.5-2.5 2.5 2.5 0 0 0 2.5 2.5zm0-3a.5.5 0 0 1 .5.5.5.5 0 0 1-1 0 .5.5 0 0 1 .5-.5zM28.29 18.29l-10 10a1 1 0 1 0 1.42 1.41l10-10a1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0z" /></svg>

                            <span className='ps-1'>offer</span>
                        </div>


                        {/* cart  */}
                        <div className='text-light me-2 me-md-3' onClick={hendlecart} style={{ display: "flex", alignItems: "center", cursor: "pointer" }} >
                            <div className="d-flex justify-content-start align-items-center text-decoration-none position-relative mx-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="carth p-0 drop-dwon-profile" viewBox="0 0 16 16" >
                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div
                                    className='cartvalu'
                                    style={{ color: 'white', backgroundColor: 'red', display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", position: "absolute", top: "0" }}
                                >
                                    {numOfItem}
                                </div>
                                {/* style={{ color: 'white', marginLeft: '-10px', marginRight: '10px', backgroundColor: 'orange', borderRadius: '5px' }} */}
                            </div>

                            cart
                        </div>

                        {/* login logout  */}
                        {login ?
                            <div className="" style={{ cursor: "pointer" }}>
                                <div className="dropdown">
                                    <span
                                        className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                        data-bs-display="static"
                                        style={{ color: "white" }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='white' className='carth' viewBox="0 0 48 48">
                                            <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 12 C 22.125 12 20.528815 12.757133 19.503906 13.910156 C 18.478997 15.063179 18 16.541667 18 18 C 18 19.458333 18.478997 20.936821 19.503906 22.089844 C 20.528815 23.242867 22.125 24 24 24 C 25.875 24 27.471185 23.242867 28.496094 22.089844 C 29.521003 20.936821 30 19.458333 30 18 C 30 16.541667 29.521003 15.063179 28.496094 13.910156 C 27.471185 12.757133 25.875 12 24 12 z M 24 15 C 25.124999 15 25.778816 15.367867 26.253906 15.902344 C 26.728997 16.436821 27 17.208333 27 18 C 27 18.791667 26.728997 19.563179 26.253906 20.097656 C 25.778816 20.632133 25.124999 21 24 21 C 22.875001 21 22.221184 20.632133 21.746094 20.097656 C 21.271003 19.563179 21 18.791667 21 18 C 21 17.208333 21.271003 16.436821 21.746094 15.902344 C 22.221184 15.367867 22.875001 15 24 15 z M 17.259766 26 C 15.478261 26 14 27.477066 14 29.259766 L 14 30.341797 C 14 32.32976 15.256514 34.057405 17.046875 35.199219 C 18.837236 36.341033 21.229275 37.001953 24 37.001953 C 26.770725 37.001953 29.162764 36.341033 30.953125 35.199219 C 32.743486 34.057405 34 32.32976 34 30.341797 L 34 29.259766 C 34 27.478261 32.522934 26 30.740234 26 L 17.259766 26 z M 17.259766 29 L 30.740234 29 C 30.901535 29 31 29.09927 31 29.259766 L 31 30.341797 C 31 31.053834 30.535733 31.907236 29.339844 32.669922 C 28.143954 33.432608 26.284275 34.001953 24 34.001953 C 21.715725 34.001953 19.856046 33.432608 18.660156 32.669922 C 17.464267 31.907236 17 31.053834 17 30.341797 L 17 29.259766 C 17 29.098465 17.09927 29 17.259766 29 z"></path>
                                        </svg>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><span onClick={handleClickProfile} className="dropdown-item" style={{ cursor: 'pointer' }}>Profile</span></li>
                                        <li
                                            className='dropdown-item'
                                            onClick={handleLogout}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Log Out
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            :
                            <div style={{ cursor: 'pointer' }}>
                                <div className="mx-1 mx-md-3 rounded" style={{}} onClick={hendlelogin}>
                                    <span className='d-block d-md-none'>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill='white' className='carth' viewBox="0 0 24 24"><g data-name="60.User"><path d="M12 12a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM18.9 21.166l-1.972-.332a5 5 0 1 0-9.862 0l-1.966.332a7 7 0 1 1 13.806 0z" /><path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z" /></g></svg>
                                    </span>
                                    <span className='px-1 px-md-3 py-1 d-none d-md-block text-light' style={{}}>Login | Sign up</span>
                                </div>
                            </div>

                        }
                    </div>
                    {/* <div className="search  me-2 mx-2 location-search-under" >
                        <div style={{ display: 'flex' }}>
                            <input className="form-control" name='input' onChange={handleFilter} placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc" style={{ width: '75vw', fontSize: '0.9em', borderTopLeftRadius: '6px', borderTopRightRadius: '0px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '0px' }} />
                            <button type="button" onClick={searchMedicine} className="btn" style={{ backgroundColor: '#febd69', color: 'black', borderTopLeftRadius: '0px', borderTopRightRadius: '6px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '6px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                </svg>
                            </button>
                        </div>
                        {chooseProduct.length !== 0 && (
                            <div className="inputResult" onClick={handleClick}>
                                {chooseProduct.map((product, index) => {
                                    return <p onClick={() => setValueTOFilter(product.name)} style={{ textDecoration: 'none', color: 'black' }}><p style={{ cursor: 'pointer', padding: '0px' }} key={index}>{product.name}</p></p>
                                }
                                )}
                            </div>
                        )}
                    </div> */}
                </nav>
                {/* <div className='headerWidth mx-auto gap-2' style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='text-light fs-8'>Search for available pincode</span>
                    <span className='text-primary fs-8 fw-semibold' style={{ cursor: 'pointer' }} onClick={handleShow}>here</span>
                </div> */}

                <div className="d-md-none mx-3" >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className='d-block d-md-none' onClick={() => { setshowmenu(!showmenu) }} x="0px" y="0px" width="7vw" height="5vh" viewBox="0 0 50 50">
                <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"></path>
              </svg> */}
                    
                    <div className='' style={{ display: 'flex', alignItems: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`${location.pathname === "/sub-admin/home" ? "d-block" : "d-none"}`}  onClick={() => { setshowmenu(!showmenu) }} fill='white' x="0px" y="0px" width="7vw" height="5vh" viewBox="0 0 50 50">
                        <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"></path>
                    </svg>
                        <input
                            className="form-control text-dark ms-2"
                            name="input"
                            onChange={handleFilter}
                            placeholder="Search Medicine Etc"
                            value={values.input}
                            style={{
                                width: '100vw',
                                height: "3.5vh",
                                fontSize: '0.8rem',
                                borderTopLeftRadius: '3px',
                                borderTopRightRadius: '0px',
                                borderBottomLeftRadius: '3px',
                                borderBottomRightRadius: '0px',
                                backgroundColor: "#eaecee",
                                color: "black"
                            }}
                        />

                        <button type="button" onClick={searchMedicine} className="btn" style={{ backgroundColor: '#febd69', color: 'black', borderTopLeftRadius: '0px', borderTopRightRadius: '3px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '3px', height: "3.5vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ fontWeight: "bold" }} width="16" height="16" fill="black" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                            </svg>

                        </button>
                    </div>
                    {chooseProduct.length !== 0 && (
                        <div className="inputResult z-3" onClick={handleClick} style={{}}>
                            {chooseProduct.map((product, index) => {
                                return <div className='' onClick={() => setValueTOFilter(product.name)} style={{ color: 'black' }}>
                                    <p style={{ cursor: 'pointer', padding: '0px' }} key={index}>{product.name}</p>
                                </div>
                            }
                            )}
                        </div>
                    )}
                </div>


                {/* drop pin code scarch  */}

                <div className="dropdown me-2 dropdown-location "  >


                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h5>Find Nearby Services</h5>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className='dis-flex'> <Suspense fallback={<HashLoader color="#36d7b7" />}> <ChoosePinCodeModal onHide={handleClose} /></Suspense> </div>
                        </Offcanvas.Body>
                    </Offcanvas>


                </div>



            </div>

        </>
    )
}
