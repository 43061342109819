import { useState, useContext, createContext, useEffect } from 'react';
import React from 'react';

// Create Auth Context
const AuthContext = createContext();


// Provide Auth Context to components
export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const storedUserId = localStorage.getItem('user_id');
        const storedRole = localStorage.getItem('role');
        const storedIsLoggedIn = localStorage.getItem('LogedIn');

        if (storedUserId) {
            setUserId(storedUserId);
            setIsLoggedIn(storedIsLoggedIn === 'true');  // Ensure to set boolean
        }

        if (storedRole) {
            setRole(storedRole);
        }
    }, []);

    const login = (id, role, LogedIn) => {
        localStorage.setItem('user_id', id);
        localStorage.setItem('role', role);
        localStorage.setItem('LogedIn', LogedIn);
        setIsLoggedIn(true);
        setRole(role);
        setUserId(id);
    }


    const logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('LogedIn');
        // localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('current_pin_code');
        setIsLoggedIn(false);
        setUserId('');
        setRole('');
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, role, userId }}>
          {children}
        </AuthContext.Provider>
    );
}

// Hook to use AuthContext
export function useAuth() {
    return useContext(AuthContext);
}